@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600;700;800&display=swap');

:root {
    --bg-color: rgb(22,22,22);
    --secondary-color: rgb(41, 41, 41);
    --game-bg-color: rgb(73, 73, 73);
    --accent-color: rgb(94, 24, 25);
    --active-color: rgb(167, 118, 119);
    --default-font-color: rgb(255,255,255);
    --default-font : 'Inconsolata', monospace;
}

body {
    background-color: var(--bg-color);
    color: var(--default-font-color);
    font-family: var(--default-font);
    margin: 0;
}

main {
    width: 80%;
    margin-inline: auto;
}

/* -- ERROR -- */

.error {
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.error span {
    color: var(--active-color);
}

/* -- LOADING -- */

main#loader {
    width: 80vw;
    height: 80vh;
    margin-inline: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading {
    width: 10vw;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: nowrap;
    gap: 2em;
}

.loading img {
    transform: scale(0.5);
}

.loading img:first-of-type {
    animation: scaleImg 0.5s ease-in-out infinite;
}

.loading img:nth-of-type(2) {
    animation: scaleImg 0.5s 0.1s ease-in-out infinite;
}

.loading img:nth-of-type(3) {
    animation: scaleImg 0.5s 0.2s ease-in-out infinite;
}

@keyframes scaleImg {
    0% {
        transform: scale(0.35)
    }
    50% {
        transform: scale(0.5);
    }
    100% {
        transform: scale(0.35);
    }
}

/* -- NAV -- */

nav {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 3rem;
    margin-inline: auto;
    background-color: var(--secondary-color);
    border-bottom: 5px solid var(--accent-color);
}

nav a {
    all: unset;
    cursor: pointer;
    position: relative;
}

nav a::after {
    content: "";
    width: 0;
    position: absolute;
    bottom: -.25rem;
    left: 0;
    border-bottom: 1px solid var(--active-color);
    transition: width .5s ease-in-out;
}

nav a:focus {
    outline: 1px solid white;
}

nav a.nav-active {
    color: var(--active-color);
}

nav a:hover::after {
    width: 100%;
}

nav a h2 {
    all: unset;
    font-weight: 700;
    font-size: 1.75rem;
}

/* -- USERNAME FORM -- */

main#username-set {
    height: 80vh;
}

main#username-set h1 {
    padding: 2rem 3rem 1rem;
}

main#username-set p {
    padding: 1rem 3rem;
}

.username-set-form {
    width: 10vw;
    margin-inline: auto;
    margin-top: 3rem;
    padding: 2em;
    display: flex;
    flex-direction: column;
    gap: 1em;
    border: 5px solid black;
    border-radius: 1em;
    background-color: var(--secondary-color);
}

.username-set-form-error {
    font-size: .8em;
    color: rgb(211, 72, 72);
}

.username-set-form input {
    border-radius: .5em;
    border: none;
    padding: .25em;
}

.username-set-form button {
    all: unset;
    width: 80%;
    text-align: center;
    background-color: var(--accent-color);
    padding: .25em;
    border-radius: .5em;
    cursor: pointer;
    margin-inline: auto;
}

.username-set-form button:focus {
    outline: 1px solid white;
}

.username-set-form button:hover {
    filter: hue-rotate(30deg) brightness(120%) contrast(200%);
}

/* -- CREATE ROOM FORM -- */

main#dashboard {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 2em;
}

main#dashboard h1 {
    width: 100%;
}

.create-room-form,
.room-list {
    width: 30%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 30em;
    background-color: var(--secondary-color);
    padding: 1em;
    outline: 5px solid black;
    border-radius: 2em;
    position: relative;
}

.create-room-form h2,
.room-list h2 {
    color: var(--active-color);
    position: absolute;
    top: 1.5rem;
    letter-spacing: .125em;
    font-size: 1.75em;
}

.create-room-form .create-room-form-input {
    display: flex;
    flex-direction: column;
}

.create-room-form input {
    border-radius: .5em;
    border: none;
    padding: .25em;
}

.create-room-form label {
    font-weight: 300;
    font-size: .9em;
}

.create-room-form .create-room-form-radio {
    display: flex;
    align-items: center;
    gap: .5em;
    margin-bottom: .5em;
}

.create-room-form button {
    all: unset;
    width: 80%;
    text-align: center;
    background-color: var(--accent-color);
    margin-top: 2em;
    padding: .25em;
    border-radius: .5em;
    cursor: pointer;
    margin-inline: auto;
}

.create-room-form button:focus {
    outline: 1px solid white;
}

.create-room-form button:hover {
    filter: hue-rotate(30deg) brightness(120%) contrast(200%);
}

.room-list-container {
    margin-top: 7em;
    width: 80%;
    height: 90%;
    display: flex;
    flex-direction: column;
    gap: .25em;
}

.room-list-single {
    background-color: black;
    padding: .5em;
    cursor: pointer;
    border-radius: .5em;
}

.room-list-single:hover {
    background-color: var(--active-color);
}

.room-list-single a {
    padding: 1rem .5rem;
    color: var(--default-font-color);
    text-decoration: none;
}

.room-list-single span {
    float: right;
    padding: 0 .5rem;
}

/* -- GAME CONTAINER -- */

main#game {
    width: 100%;
}

main#game h1 {
    width: 80%;
    margin-inline: auto;
}

.game {
    display: flex;
    justify-content: space-evenly;
    padding: 3em;
    flex-wrap: wrap;
}

#game-container {
    box-sizing: border-box;
    background-color: var(--game-bg-color);
    position: relative;
    margin-inline: auto;
    width: auto;
    align-items: center;
    justify-content: space-around;
    display: grid;
    grid-template-columns: repeat(var(--grid-width), 1fr);
    grid-template-rows: repeat(var(--grid-height), 1fr);
    outline: 5px solid black;
    border-radius: 1em;
}

/* blocks */

#game-container .game-block {
    background-image: url("../img/ground.png");
    background-position: center;
    background-size: 105%;
    width: 2.5em;
    aspect-ratio: 1/1;
    border: 1px solid rgba(0, 0, 0, 0.26);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

#game-container .game-block.empty {
    background-color: grey;
    opacity: 0.6;
}

#game-container .game-block.colliding {
    border-color: red;
}

#game-container .game-block.wall {
    background-color: black;
    background-image: url("../img/wall.png");
    background-position: center;
    background-size: 105%;
}

#game-container .game-block.bonus {
    background-color: gold;
    background-image: url("../img/bonus.png");
    background-position: center;
    background-size: 105%;
}

/* models */

.player {
    position: absolute;
    width: 1.75em;
    height: 1.75em;
    background-image: url("../img/player.png");
    background-position: center;
    background-size: 100%;
    transition: top .05s, left .05s;
    z-index: 3;
}

.bomb {
    position: absolute;
    width: 1.5em;
    height: 1.5em;
    background-image: url("../img/bomb.png");
    background-position: center;
    background-size: 100%;
    z-index: 1;
}

[data-color='red'] {
    filter: hue-rotate(305deg) brightness(130%) contrast(200%);
}

[data-color='green'] {
    filter: hue-rotate(100deg) brightness(140%) contrast(200%);
}

[data-color='blue'] {
    filter: hue-rotate(160deg) brightness(150%) contrast(200%);
}

[data-color='gold'] {
    filter: hue-rotate(10deg) brightness(160%) contrast(200%);
}

.flames {
    position: absolute;
    background-image: url("../img/flames.png");
    background-position: center;
    background-size: 100%;
    z-index: 2;
}

.bonus-for-player {
    width: 1.3em;
    height: 1.3em;
}

.bonus-for-player.bonus-speed {
    background-image: url("../img/bonus-speed.png");
    background-position: center;
    background-size: 100%;
}

.bonus-for-player.bonus-cd {
    background-image: url("../img/bonus-cooldown.png");
    background-position: center;
    background-size: 100%;
}

/* playerlist */

.game-playerlist {
    box-sizing: border-box;
    outline: 5px solid black;
    width: 20%;
    height: 100%;
    padding: 1rem;
    border-radius: 1em;
    background-color: var(--secondary-color);
    display: flex;
    flex-direction: column;
}

.game-playerlist h2 {
    order: 1;
}

.playerlist-player {
    display: flex;
    justify-content:  space-between;
    align-items: center;
    margin-top: 2em;
    order: 3;
    transition: order 0.5s ease-out;
}

.playerlist-player.current {
    color: var(--active-color);
    font-weight: 700;
    order: 2;
}

.playerlist-image {
    width: 2em;
    height: 2em;
    background-image: url("../img/player.png");
    background-position: center;
    background-size: 100%;
}

.playerlist-player.dead, .playerlist-player.current.dead {
    order: 4;
}

.playerlist-player.dead .playerlist-name {
    text-decoration: line-through;
}

.playerlist-player.dead .playerlist-image {
    filter: saturate(0);
}

/* cooldowns and bonuses */

.game-player-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 20%;
    margin-left: 20em;
}

.bomb-cooldown-container,
.player-speed-container {
    background-color: var(--secondary-color);
    padding: .5em;
    border-radius: 1em;
    position: relative;
    width: 2em;
    height: 2em;
    overflow: hidden;
}

.bomb-cooldown-animation {
    position: absolute;
    width: .25em;
    height: 3em;
    top: calc(50% - 3em);
    left: calc(50% - .125em);
    background-color: var(--active-color);
    transform: rotate(0);
    transform-origin: bottom;
    border-radius: 20%;
    display: none;
    transition: transform .1s ease-in-out;
    z-index: 1;
}

.bomb-cooldown-animation.cooldown-waiting-visible {
    display: block;
}

.bomb-cooldown-image {
    width: 100%;
    height: 100%;
    background-image: url("../img/bomb.png");
    background-position: center;
    background-size: 100%;
}

.bomb-cooldown-image.cooldown-wait {
    filter: saturate(0);
}

.player-speed-image {
    width: 100%;
    height: 100%;
    background-image: url("../img/bonus-speed.png");
    background-position: center;
    background-size: 100%;
    opacity: .25;
}

.player-speed-value {
    width: 2rem;
    position: absolute;
    text-align: center;
    font-size: .7rem;
    top: calc(50% - .7rem);
    left: calc(50% - 1rem);
    color: var(--default-font-color);
}